@import url('https://fonts.googleapis.com/css2?family=Cabin&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=ABeeZee&display=swap');

html,
body,
#root {
  height: 100%;
}

@media only screen and (min-width: 320px) {
  html,
  body,
  #root {
  }
}
